.suggestedCtr {
        background-color: white;
        border: 1px solid lightgrey;
        border-radius: 8;
        padding: 8px;
        font-size: 13px;
        color: #6e7191;
}

.imgCtr {
    background-color: #387cde;
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
        background-color: #eff0f6;
        height: 230px;
        padding: 20px;
        border-radius: 8px;
        overflow-y: auto;
        overflow-x: hidden;
      }