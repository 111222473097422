/* Floating Label CSS */
.floating-label-group {
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .floating-label {
    position: absolute;
    pointer-events: none;
    left: 1.25rem;
    top: 1.25rem;
    transition: 0.2s ease all;
    opacity: 0.5;
  }
  
  .floating-label-input {
    border: 1px solid #ced4da;
    border-radius: 12px;
    padding: 1.25rem;
    width: 100%;
    transition: 0.2s ease all;
  }
  
  .floating-label-input:focus,
  .floating-label-input:not(:placeholder-shown) {
    padding-top: 1.75rem;
    /* padding-bottom: 0.75rem; */
  }
  
  .floating-label-input:focus ~ .floating-label,
  .floating-label-input:not(:placeholder-shown) ~ .floating-label {
    top: 0.5rem;
    left: 1rem;
    font-size: 0.75rem;
    opacity: 1;
  }
  
  .read-only-input {
    border: none;
    background-color: transparent;
    padding: 1.25rem;
    width: 100%;
  }
  